/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublishRequest } from '../models/PublishRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PublishRequestService {
  /**
   * Creates a PublishRequest resource.
   * Creates a PublishRequest resource.
   * @param id PublishRequest identifier
   * @param requestBody The new PublishRequest resource
   * @returns PublishRequest PublishRequest resource created
   * @throws ApiError
   */
  public static publishEntry(
    id: string,
    requestBody: PublishRequest,
  ): CancelablePromise<PublishRequest> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/entries/{id}/publish',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
