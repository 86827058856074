/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VisionRequest } from '../models/VisionRequest';
import type { VisionRequest_Entry } from '../models/VisionRequest_Entry';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class VisionRequestService {
  /**
   * Creates a VisionRequest resource.
   * Creates a VisionRequest resource.
   * @param requestBody The new VisionRequest resource
   * @returns VisionRequest_Entry VisionRequest resource created
   * @throws ApiError
   */
  public static dreamVision(
    requestBody: VisionRequest,
  ): CancelablePromise<VisionRequest_Entry> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dreams/vision/create',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
}
