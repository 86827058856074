import { useState } from 'react'
import { useSelector } from '../../ducks/root-reducer'
import { selectPrivateGroupId } from '../../ducks/groups/groups'
import { DreamEllipsisNavigationProp } from '../../components/composite/feed/dream-ellipsis'
import { useTranslation } from 'react-i18next'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useActiveGroupId } from '../useActiveGroupId'
import { idEquals } from '../../ducks/helpers'
import { elsewhereToast } from '../../components/common/toast/toast'
import { TEMP_DREAM_ID_PREFIX } from '../../constants/constants'
import { selectUser } from '../../ducks/user/user'
import { useDream } from '../useDream'
import { useUserDate } from '../useUserDate'
import Clipboard from '@react-native-clipboard/clipboard'

export const useDreamActions = (dreamId?: string) => {
  // STATE
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)

  // HOOKS
  const privateGroupId = useSelector(selectPrivateGroupId)
  const { dream } = useDream(dreamId || '')
  const { t } = useTranslation()
  const navigation = useNavigation<DreamEllipsisNavigationProp>()

  // VARS
  const isDraft = Boolean(dream?.isDraft)
  const dreamType = dream?.type
  const activeGroupId = useActiveGroupId()
  const isUserPrivateGroup = idEquals(activeGroupId, privateGroupId)
  const appUser = useSelector(selectUser)
  const isUsersOwnDream = idEquals(dream?.userId, appUser?.id)
  const canOpen = Boolean(dreamId && !dreamId.includes(TEMP_DREAM_ID_PREFIX))
  const editLabel = isDraft ? t('editDraftPage.header') : t('common.edit')
  const { dateString } = useUserDate(dream?.date || '')

  const copyToClipboard = async () => {
    if (!dream) {
      return
    }

    Clipboard.setString(
      `${t('common.dream_plural', { count: 1 }).toLocaleUpperCase()}:\n\n` +
        (dream?.title || dateString) +
        '\n\n' +
        dream?.description +
        (dream?.note
          ? `\n\n${t('common.note').toLocaleUpperCase()}:\n\n` + dream?.note
          : ''),
    )
    elsewhereToast.showToast({
      description: t('toast.copyToClipboard.title'),
      status: 'success',
    })
  }

  const getActionsSheetMenuItems = () => {
    if (!dream) {
      return []
    } else {
      return [
        ...(!isUserPrivateGroup && isUsersOwnDream
          ? [
              {
                label: t('common.viewInJournal'),
                closesMenu: true,
                onPress: () => {
                  navigation.push('MainTabs', {
                    screen: 'MainStack',
                    params: {
                      screen: 'PrivateDreamView',
                      params: {
                        id: dream?.id as string,
                        tab: 'dream',
                      },
                    },
                  })
                },
              },
            ]
          : []),
        ...(isDraft || !isUsersOwnDream || dreamType !== 'dream'
          ? []
          : [
              {
                label: t('common.shareSettings'),
                closesMenu: true,
                onPress: () => {
                  navigation.push('PrivateDreamShare', {
                    id: dreamId as string,
                  })
                },
              },
            ]),
        {
          label: t('common.copyToClipboard'),
          closesMenu: true,
          onPress: copyToClipboard,
        },
        ...(!isUserPrivateGroup || !isUsersOwnDream
          ? []
          : [
              {
                label: editLabel,
                closesMenu: true,
                onPress: () => {
                  isDraft
                    ? navigation.push('PrivateDraftEdit', {
                        id: dream?.id as string,
                      })
                    : navigation.push(
                        dreamType === 'journal-entry'
                          ? 'PrivateDiaryEdit'
                          : 'PrivateDreamEdit',
                        {
                          id: dream?.id as string,
                        },
                      )
                },
              },
            ]),
        ...(!isUserPrivateGroup || !isUsersOwnDream
          ? []
          : [
              {
                label: t('common.delete'),
                closesMenu: true,
                onPress: () => {
                  setIsDeleteAlertOpen(true)
                },
              },
            ]),
        {
          label: t('common.cancel'),
          closesMenu: true,
          onPress: () => {},
        },
      ]
    }
  }

  return {
    isOpen,
    setIsOpen,
    canOpen,
    isDeleteAlertOpen,
    setIsDeleteAlertOpen,
    actionSheetMenuItems: getActionsSheetMenuItems(),
  }
}
