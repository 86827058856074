import React, { memo, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from '../../../ducks/root-reducer'
import { idEquals } from '../../../ducks/helpers'
import { elsewhereToast } from '../../common/toast/toast'
import { ActionSheetMenu } from '../../common/action-sheet/action-sheet-menu'
import { ButtonIcon } from '../../common/buttons/button-icon'
import { selectUser } from '../../../ducks/user/user'
import { selectFastDreamById } from '../../../ducks/dream-tag/dream-tag'
import { useNavigation, useRoute } from '@react-navigation/native'
import {
  selectPrivateGroupId,
  selectUserGroups,
} from '../../../ducks/groups/groups'
import { DreamViewNavigationProp } from '../../../screens/Dreams/DreamView'
import { DraftViewNavigationProp } from '../../../screens/Drafts/DraftView'
import { useActiveGroupId } from '../../../hooks/useActiveGroupId'
import { DeleteDreamAlert } from './dream-ellipsis/delete-dream-alert'
import { useDreamActions } from '../../../hooks/useDreamActions'

export type DreamEllipsisNavigationProp =
  | DreamViewNavigationProp
  | DraftViewNavigationProp

const DreamEllipsis = () => {
  // SELECTORS
  const user = useSelector(selectUser)
  const joinedGroups = useSelector(selectUserGroups)
  const privateGroupId = useSelector(selectPrivateGroupId)

  // HOOKS
  const { t } = useTranslation()
  const route = useRoute<any>()
  const dreamId = route.params.id
  const navigation = useNavigation<DreamEllipsisNavigationProp>()

  // DREAM
  const dream = useSelector(selectFastDreamById(dreamId))
  const actions = useDreamActions(dream?.id)

  // VARS
  const activeGroupId = useActiveGroupId()
  const currentGroup = useMemo(() => {
    return joinedGroups.find((g) => g.id === activeGroupId)
  }, [user, privateGroupId, joinedGroups, activeGroupId, navigation, route])

  const appUser = useSelector(selectUser)
  const isLoggedInUser = idEquals(dream?.userId, appUser?.id)

  if (!dreamId || !currentGroup) {
    return null
  }

  return (
    <>
      {isLoggedInUser && (
        <ButtonIcon
          iconKey="ellipsis"
          size={'xs'}
          disabled={!actions.canOpen || !isLoggedInUser}
          variant={'ghost'}
          onPress={() => {
            if (actions.canOpen) {
              actions.setIsOpen(true)
            } else {
              elsewhereToast.showToast({
                title: 'toast.waitForImageAndTagsWarning.title',
                description: 'toast.waitForImageAndTagsWarning.description',
                status: 'warning',
              })
            }
          }}
        />
      )}
      {actions.isOpen && (
        <ActionSheetMenu
          isOpen={actions.isOpen}
          onClose={() => {
            actions.setIsOpen(false)
          }}
          menuItems={[...actions.actionSheetMenuItems]}
        />
      )}
      {dream && actions.isDeleteAlertOpen && (
        <DeleteDreamAlert
          isOpen={actions.isDeleteAlertOpen}
          onClose={() => {
            actions.setIsDeleteAlertOpen(false)
          }}
          dream={dream}
        />
      )}
    </>
  )
}

export default memo(DreamEllipsis)
