import { elsewhereToast } from '../../../components/common/toast/toast'
import { AppThunk } from '../../store'
import {
  FastGroup,
  FastGroupService,
  FastMembership,
  FastShare,
  FastShareService,
} from '../../../../../api/_openapi'
import {
  addUserGroup,
  updateUserGroup,
  setUserGroups,
  removeUserGroup,
} from '../../groups/groups'
import {
  createGroupInDb,
  fetchUserGroups,
  deleteMembership,
  deleteGroup,
  fetchGroupById,
} from '../functions/groupFunctions'
import { unshareDream } from '../../dream-tag/dream-tag'

export function loadUserGroups(): AppThunk {
  return async (dispatch: any) => {
    const groups = await fetchUserGroups()
    dispatch(setUserGroups(groups))
  }
}

export function addGroup(group: Partial<FastGroup>): AppThunk {
  return async (dispatch: any): Promise<FastGroup | null> => {
    // Add group to DB
    const createdGroup = await createGroupInDb(group)

    // Fetch group again
    if (createdGroup && createdGroup.id) {
      const fastGroup = await fetchGroupById(createdGroup.id)
      dispatch(addUserGroup(fastGroup))
      return fastGroup
    } else {
      return null
    }
  }
}

export function joinGroup(group: FastGroup): AppThunk {
  return async (dispatch: any) => {
    const newGroup = await fetchGroupById(group.id)
    dispatch(addUserGroup(newGroup))
  }
}

// Leave a group
export function leaveGroup(membership: FastMembership): AppThunk {
  return async (dispatch: any) => {
    // Delete membership
    await deleteMembership(membership.id)
    dispatch(removeUserGroup(membership.groupId))
  }
}

// Delete a group
export function deleteGroupForUser(group: FastGroup): AppThunk {
  return async (dispatch: any) => {
    // Delete membership
    deleteGroup(group.id)
      .then(() => {
        elsewhereToast.showToast({
          description: 'toast.groupDeleted.description',
          descriptionParam: group.name,
          status: 'success',
        })
        dispatch(removeUserGroup(group.id))
      })
      .catch((err) => {
        console.log('Error deleting group', err)
        elsewhereToast.showToast({
          description: 'common.errorGeneric',
          status: 'error',
        })
      })
  }
}

// Update a group
export function updateGroup(
  groupId: string,
  group: Partial<FastGroup>,
): AppThunk {
  return async (dispatch: any) => {
    try {
      const updatedGroup = await FastGroupService.updateGroup(groupId, group)
      if (updatedGroup) {
        elsewhereToast.showToast({
          description: 'toast.groupUpdated.description',
          status: 'success',
        })
        dispatch(updateUserGroup(updatedGroup))
      }
    } catch (err) {
      console.log('Error updating group', JSON.stringify(err, null, 2))
      elsewhereToast.showToast({
        description: 'common.errorGeneric',
        status: 'error',
      })
    }
  }
}

export function unshareDreamFromGroups(
  dreamId: string,
  groupIds: string[],
): AppThunk {
  return async (dispatch: any) => {
    const promises = groupIds.map((groupId) => {
      dispatch(unshareDream(dreamId))
      return FastShareService.unshareDream(dreamId, groupId)
    })
    Promise.all(promises)
      .then(() => {
        elsewhereToast.showToast({
          description: 'toast.dreamsUnshared.description',
          status: 'success',
        })
      })
      .catch((err: any) => {
        console.log('Error unsharing dream', err)
        elsewhereToast.showToast({
          description: 'Error unsharing dream',
          status: 'error',
        })
      })
  }
}
